#message-box > p {
  margin: 0px 0px 12px 0px;
  padding: 0px;
  text-align: left;
  color: #000;
}

#message-box > p:last-child {
  margin: 0px;
}
